#privacy_policy {
  h3 {
    display: inline;
  }
  ol {
    padding-left: 0;
    list-style-position: inside;
    counter-reset: item;
    & > li {
      display: block;
      &:before {
        content: counter(item) ". ";
        counter-increment: item;
        font-weight: bold;
      }
    }
  }
  ul {
    list-style: disc;
  }
}
