.heat-card {
    margin-bottom: 35px;

    .athlete-table {
        height: 0;

        .athlete-heat-row {
            td {
                border-bottom-width: 1px;
            }

            &.empty-row {
                opacity: 0.6;
                font-weight: lighter;
            }

            each(@jerseys, .(@color) {
                &.@{color} .place {
                    background-color: ~"@{jersey-@{color}}";
                    color: ~"@{jersey-@{color}-font}";
                }
            });

            .place {
                font-size: 18px;
                font-weight: 700;
                width: 24px;
                min-width: 24px;
                padding: 0 @small-spacing;

                @media @generic-phone {
                    padding: 0 @tiny-spacing;
                }
            }

            &.no-scores {
                .place {
                    padding: 0;
                }

                .name {
                    width: 100%;
                }

                &:first-child .name {
                    font-weight: inherit;
                }
            }

            &.no-jersey {
                .place {
                    min-width: 0;
                    padding-right: 0;
                }
            }

            .avatar {
                padding: @small-spacing 0;
                width: 32 + 2*@small-spacing;
                min-width: 32 + 2*@small-spacing;
                max-width: 32 + 2*@small-spacing;

                .img {
                    margin: 0 @small-spacing;
                    width: 32px;
                    height: 32px;
                }

                .bib-img {
                    flex-direction: column-reverse;

                    .bib {
                        margin-top: @small-spacing;
                    }
                }
            }

            .name {
                width: 20%;
                min-width: unset;
                max-width: 0;
                padding-right: @default-spacing;


                @media @generic-phone {
                    width: 30%;
                }

                @media @generic-phone-portrait {
                    width: 100%;
                    max-width: 0;
                    padding-right: @small-spacing;
                }

                .team-name {
                    font-size: 12px;
                    margin-top: @small-spacing;
                }
            }

            &:first-child .name {
                font-weight: 700;
            }

            .overall {
                border-left-width: 3px;
                background: @light-background-color;
                padding: @small-spacing;

                .total {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: @tiny-spacing;
                    min-width: 67px;
                }

                .win-by-needs {
                    font-size: @small-font-size;
                }

                @media @generic-phone {
                    width: 77px;
                    max-width: 77px;

                    .total {
                        min-width: 55px;
                        font-size: 22px;
                    }
                }
            }

            .all-ride-scores {
                width: 80%;
                vertical-align: top;
                text-align: left;
                padding: 0;

                @media @generic-phone {
                    width: 70%;
                }

                @media @generic-phone-portrait {
                    display: none;
                }

                .rides-container {
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 100%;
                }

                .ride {
                    position: relative;
                    width: 10%;
                    min-height: 100%;
                    text-align: center;
                    display: inline-block;

                    > div {
                        min-height: 3em;
                        height: 100%;
                    }

                    @media @generic-phone {
                        width: 20%;
                        min-height: 50%;

                        > div {
                            min-height: 2em;
                        }
                    }

                    .modified-score {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &.top {
                        background-color: @notify-success-color;
                    }

                    .original-score {
                        text-decoration: line-through;
                        font-size: 10px;
                        position: relative;
                        top: 0;
                        right: -30%;
                        height: 0;
                        display: block;
                    }

                    sup, sub {
                        position: absolute;
                        font-size: 10px;
                    }

                    sup {
                        left: 2px;
                        top: 7px;
                    }

                    sub {
                        right: 2px;
                        bottom: 7px;
                    }
                }
            }
        }
    }
}
