.edit-heats {
  .tabs-root {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;

    @media @generic-phone {
      width: 100vw;
    }
  }

  .tab-root {
    padding-left: @default-spacing;
  }

  .tab-wrapper {
    align-items: flex-start;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .heat-header {
    padding: 0;
    color: unset;
    font-family: unset;
    font-size: unset;
    background: none;
  }

  .judge-card {

    th.overall {
      vertical-align: top;
    }

    td.ride {
      padding: 0;
      text-align: center;
    }

    .categories {
      display: flex;
      justify-content: space-around;
      padding: @small-spacing @small-spacing 0;

      .category:not(:last-child) {
        margin-right: @default-spacing;
      }
    }

    td .categories {
      padding-bottom: @small-spacing;
    }

    th.jersey .bib {
      margin-bottom: @default-spacing
    }

    .total, .place {
      font-size: @callout-font-size;
      font-weight: bold;
      margin-bottom: @small-spacing;
    }

    .ride-total {
      font-size: @bigger-font-size;
      font-weight: bold;
      padding: @medium-spacing @small-spacing @small-spacing @small-spacing;
    }

    .original {
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: @small-font-size;
      text-decoration: line-through;
    }

    .overall {
      background-color: @light-background-color;
      text-align: center;
      width: 90px;

      .win-by-needs {
        font-size: @small-font-size;
      }
    }

    @media @generic-tablet {
      .total, .place {
        font-size: @bigger-font-size;
      }

      .ride-total {
        font-size: @main-font-size;
        margin-bottom: 0;
        padding-bottom: @tiny-spacing;
      }

      .categories {
        font-size: @small-font-size;
      }

      .categories {
        padding-top: @tiny-spacing;

        .category:not(:last-child) {
          margin-right: @small-spacing;
        }
      }
    }

    @media @generic-phone-portrait {
      .overall {
        min-width: 60px;
      }
    }
  }
}

.judge-card.edit-names {
  thead th.name {
    font-weight: bold;
  }

  tbody th.name {
    user-select: none;

    &.dragging {
      * {display: none;};
      background-color: @dragging-color;
      cursor: grabbing;
    }

    &.cannot-drop:not(.dragging) {
      * {opacity: 0.6;}
      background: repeating-linear-gradient(
              45deg,
              rgba(255, 255, 255, 0.5),
              rgba(255, 255, 255, 0.5) 5px,
              rgba(150, 150, 150, 0.5) 5px,
              rgba(150, 150, 150, 0.5) 6px
      );
    }
  }

  tbody tr:not(.empty) th.name {
    cursor: grab;
  }

  .delete {
    float: right;
    margin-right: -@default-spacing;
  }

  .drag {
    opacity: 0.6;
    float: left;
    margin-left: -@default-spacing;
    font-size: @main-font-size;
  }

  .close-add-athlete  {
    float: right;
    margin-right: -@default-spacing;
  }
}

.edit-ride-dialog {
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  .identifier-and-total {
    border-bottom: 1px solid @border-light;
    margin-bottom: @default-spacing;
  }

  .total-field {
    margin: 3 * @small-spacing 0;
  }

  .total {
    width: 200px;
    border-radius: @small-spacing;
    padding: @tiny-spacing @default-spacing;
    background-color: @disabled-input-text-color;

    &.modified {
      background-color: transparent;
    }
  }

  &.lh-dialog form {
    display: block;
  }

  .category {
    margin-top: @default-spacing;

    &:first-child {
      margin-top: 0;
    }

    .category-title {
      margin-bottom: @default-spacing;
    }

    .formField {
      display: inline-block;
      width: 33%;
      padding-right: @default-spacing;
      margin-bottom: @default-spacing;

      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  }

  .formField.chips.error {
    margin-bottom: 25px;
  }

  .modifiers {
    margin-top: @default-spacing * 2;
  }

  @media @generic-phone-portrait {
    .total {
      width: 100%;
    }

    .category .formField {
      display: block;
      width: 100%;
      padding-right: 0;
    }
  }
}