#spectate {
    h4 {
        margin: 1em 0 0.5em 0;
    }

    .column {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 0 @default-spacing;
    }

    .tiny-heat-card {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: @default-spacing;

        &:nth-child(2n + 1) {
            padding-right: @small-spacing;
        }

        &:nth-child(2n) {
            padding-left: @small-spacing;
        }
    }

    @media @tv-hd {
        .heat-card {
            &.tiny-heat-card {
                .heat-header {
                    font-size: 12px;
                }

                .athlete-table {
                    font-size: 10px;
                }
            }

            .heat-header {
                font-size: 16px;
                padding: @small-spacing;
            }

            .athlete-heat-row {
                .avatar {
                    width: 30 + 2*@small-spacing;
                    min-width: 30 + 2*@small-spacing;
                    max-width: 30 + 2*@small-spacing;
                    .img {
                        margin: 0 @small-spacing;
                        width: 30px;
                        height: 30px;
                    }
                }

                .name {
                    font-size: 14px;
                }

                .overall {
                    padding: 4px 10px;
                }

                .ride {
                    font-size: 10px;
                    min-height: 5.3em;

                    sup {
                        font-size: 8px;
                        top: 5px;
                    }

                    sub {
                        font-size: 8px;
                        bottom: 5px;
                    }

                    .original-score {
                        font-size: 8px;
                        height: 0;
                    }
                }
            }
        }
    }

}
