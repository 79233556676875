.drag-layer-wrapper {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .drag-item {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  }

  .name {
    border: 1px solid @border-light;
    padding: @default-spacing;
    background-color: white;
    font-size: @bigger-font-size;

    button.tiny .MuiSvgIcon-root {
      font-size: @bigger-font-size;
    }

    .drag {
      opacity: 0.6;
      float: left;
      margin-left: -@default-spacing;
      font-size: @main-font-size;
    }

    @media @generic-tablet {
      padding: @medium-spacing @default-spacing;
    }
  }

  .heat, .heat-badge {
    width: 100%;
  }

  .heat, .break, .name {
    height: 100%;
  }
}