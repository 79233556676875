.event-link-item {
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    margin: @small-spacing 0;
  }

  .link-item-button {
    color: currentColor;
    margin-left: @small-spacing;
    margin-right: @tiny-spacing;

    &.MuiButton-root {
      vertical-align: top;
      
      .MuiIcon-root {
        margin-right: @tiny-spacing;
      }
    }
  }
  
  .input-container {
    display: inline-flex;
    align-items: center;
    border: 1px solid @input-border-color;
    border-radius: 8px;
    width: 60%;
    overflow: hidden;

    & > a:first-child {
      flex-grow: 1;
    }

    input {
      padding: @small-spacing @default-spacing;
      border: none;
      width: 100%;
      min-height: 42px;
      text-overflow: ellipsis;
    }

    .link-item-button {
      margin-left: 0;

      &:last-child {
        margin-right: @small-spacing;
      }
    }
  }

  @media @generic-phone {
    .input-container {
      width: 100%;
    }
  }
}

.event-dashboard.wrapper, .event-form.wrapper, .entries.wrapper {
  margin: 0;
}

.judging-connection {
  .subtitle::before {
    content: "";
    display: inline-block;
    width: @small-spacing;
    height: @small-spacing;
    border-radius: 50%;
    margin-right: @tiny-spacing;
    background: @status-offline-background;
  }

  &.online .subtitle::before {
    background: @sticky-success-background;
  }

  .empty-state p:last-child {
    margin: @default-spacing auto 0 auto;
  }
}
