.lh-modal table {
    height: 100%;
    width: 100%;
    &:focus-within {
      outline: none;
    }
    td {
        text-align: center;

        h1 {
            color: white;
        }
    }

    i.icon:not(.icon-LogoWithText):not(.icon-Logo) {
        width: 60px;
        height: auto;
        .fill {
            fill: white;
        }
    }
}

.lh-dialog {
    .sticky-dialog-title {
        border-bottom: 1px solid @border-light;

        .title-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
    }

    .sticky-dialog-actions {
        border-top: 1px solid @border-light;
    }

    .sticky-dialog-actions-wrap {
      flex-wrap: wrap;
    }

    .sticky-dialog-content {
        padding-top: @default-spacing + @small-spacing !important;
    }

    .MuiDialogContent-root {
      padding-bottom: @default-spacing + @small-spacing;
      padding-top: 2px;
    }

    .formField {
        .react-select__control, .select-wrapper, .date-input-wrapper, input {
            width: 100%;
        }
    }

    .MuiDialog-paperScrollPaper form {
      height: 100%;
      min-height: 0;
      max-height: none;
      display: flex;
      flex-direction: column;
    }

    .scrollable-area {
      height: 100%;
      overflow-y: auto;
    }
}

.MuiDialogActions-root {
  .MuiButton-root.left, .loading-button.left {
    margin-right: auto;
  }


  @media @small-phone-portrait {
    &:not(.multi-buttons) {
      .MuiButton-root {
        min-width: inherit;
      }

      .MuiButton-root.left, .loading-button.left {
        font-size: 0;

        .MuiSvgIcon-root {
          margin: 0
        }
      }
    }
  }

  .MuiButton-root {
    min-width: 90px;
  }
}

@media @generic-phone {
  .MuiDialogActions-root.multi-buttons {
    flex-direction: column-reverse;

    > *, .MuiButton-root {
      width: 100%;
    }

    &.MuiDialogActions-spacing > * + * {
      margin-top: 8px;
      margin-left: 0;
    }
  }

  .MuiDialog-root {
    .MuiDialog-paper {
      margin: 48px 16px;
    }

    .MuiDialog-paperFullScreen {
      margin: 0;
    }
  }
}
