.stripy-background(@color) {
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 10px,
    @color 10px,
    @color 20px
  );
}

table.judge-card {
  position: relative;
  background-color: white;
  border-spacing: 0;
  width: 100%;

  th {
    position: sticky;
    position: -webkit-sticky;
    font-weight: normal;
    background-color: white;
    z-index: 2;
  }

  td, th {
    border-bottom: 1px solid @border-light;
    vertical-align: top;
  }

  thead th {
    top: 0;
    border-top: 2px solid @border-light;
    border-bottom: 2px solid @border-light;
    padding: @medium-spacing 0;
    vertical-align: bottom;

    &.fixed {
      z-index: 3;
      vertical-align: top;
    }

    &.name {
      white-space: nowrap;
    }

    &.ride {
      cursor: auto;
    }

    &.empty-cell {
      padding: 0;
    }

    div > div {
      padding-bottom: 0.5em;
    }

    &.ride-with-category {
      cursor: auto;
      text-align: left;

      div {
        display: inline-block;
        text-align: center;
      }
    }
  }

  tr {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  thead th:not(.fixed):not(.ride):not(.ride-with-category):not(.team-filler):not(.athlete-filler):not(.overall):not(.priority):not(.empty-cell) {
    text-align: left;
    padding-left: (@tiny-spacing + @small-spacing + @default-spacing);
  }

  &.placing {
    tbody tr {
      height: 49px !important;

      @media @generic-phone {
        height: 56px !important;
      }
    }

    tbody th {
      padding-top: 0;
      padding-bottom: 0;
    }

    tbody {
      td, th {
        vertical-align: middle;
      }

      th.name {
        width: 100%;
      }

      th.jersey .bib {
        margin: 0;
      }
    }
  }

  tbody {
    tr:not(.empty) {
      height: 120px;
    }

    td, th {
      padding: @default-spacing @small-spacing;
    }

    td.next-athlete {
      vertical-align: middle;
      cursor: pointer;

      div {
        color: @icon-font-color;
        width: @cell-size + 10px;
        font-size: @small-font-size;
        text-align: center;
      }
    }
  }

  th.name {
    left: 59px;
    border-right: 2px solid @border-light;
    padding-left: @default-spacing;
    padding-right: @default-spacing;
  }

  th.jersey {
    left: 0;
    min-width: 59px;
    width: 59px;
    padding-left: 0;
    padding-right: 0;
  }

  td.ride {
    position: relative;
  }

  .name {
    text-align: left;
    min-width: 150px;
    width: 150px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, font-size 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .edit-heat {
      float: right;
      margin-right: -@default-spacing;
    }

    .team-name {
      font-size: 12px;
      opacity: 0.7;
      margin-top: 2px;
    }
  }

  tbody tr.empty {
    height: 60px;
  }

  &.transitions.entered {
    thead .name {
      font-size: @callout-font-size;
      min-width: 200px;
      width: 300px;

      button.tiny .MuiSvgIcon-root {
        font-size: @callout-font-size;
      }
    }

    tbody tr.empty {
      height: 120px;
    }

    tbody .name {
      font-size: @bigger-font-size;

      button.tiny .MuiSvgIcon-root {
        font-size: @bigger-font-size;
      }
    }
  }

  &.hide-names {
    &:not(.edit-names) th.jersey {
      border-right: 2px solid @border-light;
    }

    &.transitions.exited th.name {
      display: none;
    }
  }

  @cell-size: 70px;

  .ride {
    width: @cell-size;
    min-width: 65px;
    cursor: pointer;

    &.warning:not(.modified):before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      border-style: solid;
      border-width: 0 0 1.5em 1.5em;
      border-color: transparent transparent transparent @score-warning-color;
    }
  }

  .add-ride {
    cursor: pointer;
    vertical-align: middle;
    padding: @default-spacing @medium-spacing;
  }

  each(@jerseys, .(@color) {
    .@{color} {
      @jersey-color: "jersey-@{color}";
      @jersey-font-color: "jersey-@{color}-font";
      @jersey-color-faded: "jersey-@{color}-faded";
      .start {
        background-color: @@jersey-color-faded;
      }
      .name {
        background-color: @@jersey-color-faded;

        &.cannot-drop {
          * {opacity: 0.8;}

          background: repeating-linear-gradient(
                  45deg,
                  @@jersey-color-faded,
                  @@jersey-color-faded 5px,
                  rgba(150, 150, 150, 0.5) 5px,
                  rgba(150, 150, 150, 0.5) 6px
          );
        }
      }

      .jersey {
        background-color: @@jersey-color;
        color: @@jersey-font-color;

        &:before {
          content: "@{color}";
          display: block;
          text-transform: capitalize;
          margin-bottom: @default-spacing;
          font-weight: bold;

          @media @generic-phone-portrait {
            content: replace("@{color}", "^(.).*$", "$1");
          }
        }
      }
    }
  });

  tbody td.athlete-filler, .athlete-filler {
    min-width: 0;
    max-width: 0;
    width: 0;
    padding: 0;
  }

  tbody td.empty-cell {
    padding: 0;
  }

  .athlete-separator {
    border-left: 1px solid @border-light;
  }

  @media @generic-tablet {
    thead th {
      padding: @small-spacing 0;
    }

    tbody {
      td, th {
        padding: @medium-spacing @small-spacing;
      }

      tr:not(.empty) {
        height: 89px;
      }
    }
  }

  @media @generic-phone-portrait {
    th.name {
      left: 35px;
      min-width: 50px;
      width: 50px;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    th.jersey {
      font-size: @small-font-size;
      min-width: 35px;
      width: 35px;
    }
  }
}

.ride {
  .ride-total {
    display: flex;
    align-items: center;
    justify-content: center;

    .ride-category {
      margin-left: @small-spacing;
    }
  }

  &.scoring-ride {
    background-color: @scoring-ride-scores-color;

    .ride-total {
      background-color: @scoring-ride-color;
    }
  }

  &.Interference {
    .stripy-background(@interference-color);
  }

  &.Double {
    .stripy-background(@double-color);
  }

  &.PriorityInterference {
    .stripy-background(@priority-interference-color);
  }

  &.AbbPenalty {
    .stripy-background(@team-penalty-color);
  }

  &.DNS {
    .stripy-background(@interference-color);
  }

  &.DSQ {
    .stripy-background(@dsq-color);
  }

  &.DNF {
    .stripy-background(@priority-interference-color);
  }

  &.DNI {
    .stripy-background(@dsq-color);
  }

  &.pending {
    background: repeating-linear-gradient(
            45deg,
            white,
            white 5px,
            #c3c3c3 5px,
            #c3c3c3 6px
    );
  }

  .pending-icon {
    position: absolute;
    top: @tiny-spacing;
    left:@tiny-spacing;
    font-size: @main-font-size;
  }
}