.loading-icon-button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 0;
    margin-bottom: @default-spacing;

    &.success {
        button:not(:disabled), .label, .MuiSvgIcon-root {
            color: @success-dark;
        }
    }

    &.danger {
        button:not(:disabled), .label, .MuiSvgIcon-root {
            color: @brand-6;
        }
    }

    .label {
        font-size: @small-font-size;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
        position: absolute;
        bottom: -@default-spacing;
        left: 0; right: 0;
    }

    .circular-progress {
        position: absolute;
        width: 52px !important;
        height: 52px !important;
        left: -2px;
        top: -2px;
    }

    &.tiny {
        width: 28px;
        height: 28px;
        padding: 0;

        .circular-progress {
            width: 31px !important;
            height: 31px !important;
        }
    }

    &:disabled {
        opacity: 0.6;
    }
}

.loading-button {
    position: relative;

    .circular-progress {
        width: 1.5rem !important;
        height: 1.5rem !important;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -0.75rem;
        margin-left: -0.75rem;
    }

    &:disabled {
        opacity: 0.6;
    }
}

button.tiny, a.tiny {
    padding: 5px !important;
    margin-top: -5px !important;
    margin-bottom: -3px !important;

    .MuiSvgIcon-root {
        font-size: @main-font-size;
    }
}
