.register-or-login-dialog {
    
    .MuiDialogTitle-root {
        text-align: center;
        padding: 0;
    }

    .MuiDialogContent-root {
        padding: 0 2px;
    }
}