@generic-tablet: ~"only screen and (max-width: 1199px)";
@generic-phone: ~"only screen and (max-width: 899px)";
@generic-phone-portrait: ~"only screen and (max-width: 599px)";
@small-phone-portrait: ~"only screen and (max-width: 400px)";
@bigger-than-generic-phone: ~"only screen and (min-width: 900px)";
@bigger-than-generic-phone-portrait: ~"only screen and (min-width: 600px)";

@tv-hd: ~"only screen and (max-width: 1900px)";

@media @generic-phone {
  .hide-mobile-landscape {
    display: none !important;
  }
}

@media @generic-phone-portrait {
  .hide-mobile {
    display: none !important;
  }
}

@media @bigger-than-generic-phone-portrait {
  .hide-desktop {
    display: none !important;
  }
}

@media @bigger-than-generic-phone {
  .hide-desktop-small {
    display: none !important;
  }
}