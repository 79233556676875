.organisations-list {
  .first-letter, .box-list {
    max-width: @page-max-width;
    margin-left: auto;
    margin-right: auto;

    @media @generic-tablet {
      margin: 0 @default-spacing;
    }
  }

  .no-results-wrapper {
    padding: 0 @default-spacing;
  }
  
  .first-letter {
    font-size: @main-font-size;
    padding-bottom: @small-spacing;
    padding-top: @default-spacing;

    @media only screen and (max-width: 1200px + @default-spacing * 2) {
      padding-left: @default-spacing;
    }

    &.search {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  section .box-list.horizontal {
    border: 0;

    ul {
      margin-top: 0;
    }

    li .organisation-item {
      padding-bottom: @small-spacing
    }
  }
}