.tiny-heat-card {
    .heat-header {
        font-size: 14px;
        padding: @tiny-spacing @small-spacing;
    }

    .athlete-table {
        font-size: 12px;

        tbody td {
            border-bottom-width: 1px;
        }

        .avatar {
            width: 25 + @default-spacing;
            min-width: 25 + @default-spacing;

            .img {
                width: 25px;
                height: 25px;
                margin: 0 @small-spacing;
                font-size: 0.75rem;
            }

            .bib-img {
                margin-left: @small-spacing;
            }
        }

        tr:not(.empty-row):not(.no-jersey) {
            .avatar {
                position: relative;
                padding-bottom: 10px;

                &:after {
                    font-weight: bolder;
                    font-size: 7px;
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    width: 100%;
                    text-transform: uppercase;
                }

                &.with-bib {
                    max-width: none;

                    &::after {
                        left: auto;
                        right: 0;
                    }
                }
            }

            each(@jerseys, .(@color) {
                &.@{color} .avatar {
                    &:after {
                        content: "@{color}";
                        width: 25 + 2*@small-spacing;
                    }
                }
            });
        }

        .total {
            font-weight: 700;
        }

        .top-rides {
            color: @secondary-font-color;
            text-align: right;
            padding-right: @default-spacing;
        }

        .place {
            color: @darker-faded-text-color;
            text-align: right;
            width: @default-spacing;
        }

        .name {
            min-width: unset;

            &.empty {
                font-weight: lighter;
            }

            .team-name {
                font-size: 10px;
                color: @secondary-font-color;
            }
        }
    }

    &.placing .athlete-table {
        .compact-result {
            .place {
                font-size: 18px;
                font-weight: bold;
                color: inherit;
            }
        }

        .name:not(.empty) {
            font-size: 14px;
            font-weight: bold;

            .team-name {
                font-size: 11px;
                font-weight: normal;
                opacity: 0.8;
            }
        }
    }
}

.tiny-heat-details .athlete-table {
    td {
        text-align: left;
        font-weight: 700;
        padding: @tiny-spacing;

        &:after {
            display: block;
            color: @darker-faded-text-color;
            font-weight: 400;
        }

        each(@jerseys, .(@color) {
            &.@{color} {
                border-left: @small-spacing solid ~"@{jersey-@{color}}";

                &:after {
                    content: "@{color}";
                }
            }
        });
    }
}