i {
  &.icon {
    display: inline-block;
    font-style: normal;

    &.icon-Ok, &.icon-Cancel {
      width: 20px;
      height: 20px;
    }

    &.danger svg {
      .stroke {stroke: @brand-6};
      .fill {fill: @brand-6};
    }

    &.success svg {
      .stroke {stroke: @brand-tertiary};
      .fill {fill: @brand-tertiary};
    }

    &.primary svg {
      .stroke {stroke: @brand-primary};
      .fill {fill: @brand-primary};
    }

    &.icon-Megaphone {
      @keyframes sound-2 {
        0%, 100% {
          opacity: 0;
        }
        20%, 80% {
          opacity: 1;
        }
      }

      @keyframes sound-1 {
        0%, 30%, 100% {
          opacity: 0;
        }
        50%, 80% {
          opacity: 1;
        }
      }

      .sound {
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;

        &.sound-1 {
          -webkit-animation: sound-1 1.5s infinite;
          -o-animation: sound-1 1.5s infinite;
          animation: sound-1 1.5s infinite;
        }

        &.sound-2 {
          -webkit-animation: sound-2 1.5s infinite;
          -o-animation: sound-2 1.5s infinite;
          animation: sound-2 1.5s infinite;
        }
      }
    }
  }
}