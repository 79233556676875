.series-form {
    .fields-area {
        padding-bottom: @default-spacing * 2;
    }

    .buttons-area {
        button:first-child {
            margin-right: @small-spacing;
        }
    }
}