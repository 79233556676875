.login-page-card {
    width: 550px;
    border-radius: 16px;

    @media @generic-phone {
        border-radius: 0;
        width: 100%;
    }
}

.login-card {
    background: white;
    padding: @default-spacing * 2;

    h1 {
        text-align: center;
        margin-bottom: @small-spacing * 3;
    }

    .formField {
        input {
            width: 100%;
        }
    }

    .actions {
        text-align: center;

        .loading-button {
            display: block;
            width: 100%;

            button {
                width: 100%;
            }
        }

        &.change-page-action {
            margin-top: @default-spacing*2;
            margin-bottom: @default-spacing;
        }
    }

    .hr-text {
        margin: @default-spacing*2 auto;
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap; 

        .text {
            position: relative;
            display: inline-block;

            &:before, &:after {
                content: ' ';
                position: absolute;
                top: 50%;
                width: 9999px;
                height: 1px;
                background: @border-light;
            }

            &:before {
                right: 100%;
                margin-right: @default-spacing;
            }

            &:after {
                left: 100%;
                margin-left: @default-spacing;
            }
        }
    }
}

.login-card-actions {
    margin: @small-spacing*3 0;
    text-align: center;
}
