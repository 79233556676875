@remote-control-height: 82px;

.remote-control {
    position: fixed;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 @default-spacing;
    min-height: @remote-control-height;
    background-color: white;
    box-shadow: 0 4px 10px 0 black;
    font-size: @bigger-font-size * 1.5;

    .heats {
        margin-right: 10px;

        .heat-header {
            display: table-cell;
            background: none;
            color: @main-font-color;
            font-size: 14px;
            padding: 0;

            &:first-child {
                padding-right: @default-spacing * 2;
            }

            &+ .heat-header{
                border-left: 1px solid gray;
                padding-left: @default-spacing * 2;
            }
        }

        .heat-time {
            font-weight: bold;
            font-size: 24px;
            display: block;
            border: none;
            margin: 4px 0 0 0;
            padding: 0;

            &.flag span {
                background: none;
                padding: 0;
                color: inherit;
            }
        }
    }

    .remote-actions {
        display: flex;
        margin-left: auto;
    }

    .remote-action {
        margin-left: 2 * @default-spacing;
        &:first-child {
            margin-left: 0;
        }
    }

    @media @generic-tablet {
        .remote-action {
            margin-left: @default-spacing;
        }
    }

    @media @generic-phone {
        .heats .heat-header {
            &:first-child, &:last-child {
                padding: 0;
                margin: 0;
                border: none;
            }
        }

        .hide-mobile-landscape {
            display: none !important;
        }
    }

    .start, .end {
        button {
            padding: 2px;
        }

        .MuiSvgIcon-root {
            font-size: 44px;
        }
    }
}

.did-not-compete-dialog {
    .formField {
        padding: @default-spacing;
        border: 1px solid @main-border-color;
        border-bottom-width: 0;
        margin-bottom: 0;

        &:last-child {
            border-bottom-width: 1px;
        }
    }

    each(@jerseys, .(@color) {
        .@{color} {
            @jersey-color: "jersey-@{color}";
            @jersey-font-color: "jersey-@{color}-font";

            &:after {
                content: replace("@{color}", "^(.).*$", "$1");
                width: 30px;
                text-align: center;
                background-color: @@jersey-color;
                color: @@jersey-font-color;
                display: block;
                text-transform: capitalize;
                font-weight: bold;
                padding: @default-spacing 0;
                position: absolute;
                top: -@default-spacing;
                left: 2 * @default-spacing;
                bottom: -@default-spacing;
            }

            label {
                position: relative;
                padding: 0 0 0 90px;
                z-index: 1;
                margin: 0;

                & + svg {
                    z-index: 1;
                }
            }
        }
    });

    label {
        padding: 0 0 0 10px;
        margin: 0;
    }
}
