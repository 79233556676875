.event-form {
  &.wrapper {
    @media @generic-tablet {
      max-width: 100vw;
    }
  }

  .field-group-extended {
    .formField {
      .select-wrapper, input, .react-select__control {
        width: 100%;
      }
    }
    .delete-row {
      align-self: center;
    }
  }

  .custom-pricing {
    .button.first-add-price-rule {
      margin-top: -@default-spacing;
    }
  }

  .rule-heading-container h4 {
    margin-top: 0;
  }

  .checkbox-children {
    padding: @default-spacing 0 0 @default-spacing + @small-spacing;

    .rule-heading-container {
      display: flex;
      align-items: flex-start;

      .delete-row {
        margin-left: auto;
        align-self: flex-start;
      }

      .rule-heading {
        margin-bottom: @default-spacing;
      }

      @media @bigger-than-generic-phone {
        position: relative;

        .rule-heading {
          display: none;
        }

        .delete-row {
          position: absolute;
          right: -(@default-spacing + @small-spacing);
          transform: translateY(100%);
        }
      }
    }

    .field-group-extended {
      flex-wrap: wrap;

      @media @generic-phone {
        .formField {
          flex-basis: 100%;
        }
      }
    }
  }

  .custom-pricing, .checkbox-children {
    @media @bigger-than-generic-phone {
      width: 60%;

      &.wider {
        width: 85%;
      }
    }
  }

  .submission {
    display: block;
    margin-bottom: 30px;

    @media @generic-phone {
      width: 100%;
    }

    .submit {
      padding: 10px 30px;
      font-size: 24px;

      @media @generic-phone {
        width: 100%;
      }
    }

    .danger {
      display: block;
      margin-top: @default-spacing;
    }
  }
}