@field-separator: 35px;
@transition-time: 200ms;

.submission {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: center;

    .spinner {
        display: inline-block;
    }
}

.submit {
    display: inline-block;
    position: relative;
    font-size: 18px;
}

.formField {
    position: relative;
    margin-bottom: @field-separator;
    &:last-child {
        margin-bottom: 0;
    }

    .error-message {
        font-size: 12px;
        position: relative;
        height: 0;
        overflow: visible;
        line-height: 22px;
        width: 100%;
        color: @error-color;
    }

    input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        min-height: 42px;
    }

    .input-wrapper {
        position: relative;
    }

    .start-adornment {
        position: absolute;
        top: 1px;
        left: 1px;
        padding: @small-spacing @medium-spacing;
        line-height: 24px;
        background: #efefef;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        font-weight: 700;
    }

    .start-adornment + input {
        padding-left: 63px;
    }

    input, textarea, select, .react-select__control, .StripeElement {
        appearance: none;
        outline: none;
        padding: @small-spacing;
        border-radius: 8px;
        border: 1px solid @input-border-color;
        width: 60%;
        line-height: 24px;
        background-color: @background-paper;

        @media @generic-phone {
            width: 100%;
        }

        transition: border-color @transition-time;

        &:hover {
            border-color: @input-hover-color;
        }

        &:focus, &.StripeElement--focus {
            border-color: @input-focus-color;
            outline: 2px solid @input-focus-color;
        }

        &:disabled {
            opacity: .6;

            &:hover, &:focus {
                border-color: @input-border-color
            }
        }
    }

    &.error {
        input, textarea, select, .react-select__control, .StripeElement--invalid {
            border-color: @input-error-color;
            &:hover {
                border-color: @input-error-color;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: @text-light;
            }
        }

        input[type="checkbox"] + label:before, input[type="checkbox"]:hover + label:before, input[type="checkbox"]:focus + label:before {
            border-color: @input-error-color;
        }

        input[type="checkbox"]:checked + label:before {
            color: @input-error-color;
        }
    }

    .checkbox-input {
        position: relative;
    }

    input[type="checkbox"] {
        appearance: none;
        background: none;
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        border: none;

        & + label {
            line-height: 1.15;
        }

        & + label:before {
            padding: 2px 0 0 3px;
            content: "";
            position: absolute;
            left: 0;
            top: -3px;
            width: 24px;
            height: 24px;
            background: @background-paper;
            border: 1px solid @input-border-color;
            border-radius: 4px;
            cursor: pointer;
            box-sizing: border-box;
        }

        &:disabled:hover + label:before, &:disabled:focus + label:before {
            border-color: @input-border-color;
        }

        &:hover + label:before {
            border-color: @input-hover-color;
        }

        &:focus {
            outline: none;
        }

        &:focus + label:before {
            outline: 3px solid @input-focus-color;
            outline-offset: 2px;
        }

        &:checked {
            background: none;
        }

        &:checked + label {
            &:before {
                color: @text-contrast;
                background-color: @main-font-color;
                border: none;
            }

            & + svg {
                color: @text-contrast;
                position: absolute;
                top: -1px;
                left: 2px;
                pointer-events: none;
                width: 20px;
                height: 20px;
            }
        }

        &:checked:hover + label::before {
            background-color: @input-hover-color;
            border: none;
        }

        &:disabled + label:after, &:disabled + label {
            opacity: .6;
        }
    }

    .radio-input {
        position: relative;
        display: inline-block;
        margin-top: @small-spacing;
    }

    input[type="radio"] {
        appearance: none;
        background: none;
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        border: none;

        &:focus {
            outline: none;
        }
    }

    .select-wrapper {
        position: relative;
        width: 60%;

        @media @generic-phone {
            width: 100%;
        }

        select {
            width: 100%;
            min-height: 42px;
            height: 42px;
            background-color: white;
            padding-right: 2 * @default-spacing;
        }

        .MuiBox-root {
            pointer-events: none;
        }
    }

    textarea {
        height: 5em;
    }

    .checkbox-input label {
        font-size: 16px;
        font-weight: normal;
        cursor: pointer;
        margin-left: 2 * @default-spacing;
    }

    label {
        display: block;
        font-weight: 700;
        line-height: 24 / 16;
        margin-bottom: @tiny-spacing;
        word-break: break-word;
    }

    .radio-group {
        label {
            cursor: pointer;
            display: inline;
        }

        &.multiple {
            label {
                font-weight: normal;
                margin: 0 2 * @default-spacing;
            }

            input + label:before {
                content: "";
            }

            input:hover + label:after {
                border-color: @input-hover-color;
            }

            input:focus + label:after {
                outline: 3px solid @input-focus-color;
                outline-offset: 2px;
            }

            input:checked {
                background: none;
            }

            input:checked + label:before {
                content: '';
                width: @default-spacing;
                height: @default-spacing;
                border-radius: @default-spacing;
                position: absolute;
                top: 0px;
                left: @tiny-spacing;
                z-index: 2;
                background-color: @input-hover-color;
                border: none;
            }

            input + label:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                margin-top: -4px;
                width: 24px;
                height: 24px;
                background: @background-paper;
                border: 1px solid @input-border-color;
                border-radius: 24px;
                cursor: pointer;
                transition: border-color @transition-time;
            }
        }
    }

    a.add {
        font-size: 16px;
        text-decoration: underline;
    }

    &.checkbox-group {
        width: 60%;

        @media @generic-phone {
            width: 100%;
        }

        .formField.checkbox {
            margin-bottom: 0;
            margin-top: @small-spacing + 2;
            &.error .error-message {
                font-size: 10px;
                line-height: 0;
                float: left;
                left: 30px;
            }

            .disabled {
                opacity: 0.35;
            }
        }

        .new-division {
            clear: both;
            margin: @small-spacing 0 @default-spacing 0;

            .delete-new-division {
                position: relative;
                z-index: 2;
                float: left;
                margin: -2px @small-spacing 0 0;
            }
        }

        .field-group .formField {
            margin-bottom: 0;
        }
    }

    .react-select {
        .react-select__control.react-select__control--is-focused {
            border-color: @input-focus-color;
            box-shadow: none;
        }

        .react-select__control {
            box-shadow: none;
            line-height: 16px;

            .react-select__input input {
                line-height: 16px;
            }

            &:focus-within {
                outline: 2px solid @input-focus-color !important;
            }
        }

        .react-select__control {
            padding: 0;
        }

        .react-select__value-container {
            padding: @small-spacing;
        }

        .react-select__menu {
            width: 60%;
            border-radius: 2px;
            font-size: 16px;
            font-weight: 300;
            z-index: 3;
        }

        .react-select__dropdown-indicator, .react-select__clear-indicator {
            padding: 0 @small-spacing;
            color: @main-font-color;
        }

        .react-select__indicator-separator {
            background-color: @input-border-color;
        }
    }

    &.creatable-select.small-multi {
        .react-select.is-multi {
            .react-select__multi-value , .react-select__multi-value__label{
                width: 100%;
            }

            .react-select__control {
                width: 170px;
                min-width: 170px;
            }
        }
    }

    .field-group {
        .formField {
            display: inline-block;
            margin-right: @small-spacing;
            margin-bottom: @default-spacing;

            label {
                font-size: 16px;
                font-weight: normal;
                margin-right: @tiny-spacing;
                margin-bottom: 0;
            }

            &:not(.checkbox) label {
                display: inline;
            }

            input {
                display: inline;
                padding: 1px @tiny-spacing;
                text-align: center;
            }

            .error-message {
                position: absolute;
                width: 200px;
                line-height: 16px;
            }
        }

        .delete {
            margin-right: @tiny-spacing;
        }

        &.field-group-with-select {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;

            &:first-of-type {
                margin-top: -@tiny-spacing; // to match other inputs' height
            }

            .formField.checkbox {
                flex: 1;
            }

            .formField.select {
                flex-basis: 100%;
                margin-right: 0;

                &.error {
                    margin-bottom: @small-spacing;
                }

                label {
                    display: none;
                }

                .select-wrapper, .error-message {
                    margin-left: @default-spacing + @small-spacing;
                }

                .select-wrapper {
                    width: 50%;
                    margin-top: @small-spacing;
                    
                    @media @generic-phone {
                        width: auto;
                    }
                }
            }
        }
    }

    .field-group-extended {
        display: flex;
        align-items: flex-end;

        .formField {
            margin-right: @small-spacing;
            margin-bottom: @default-spacing;
            flex: 1;

            label {
                display: block;
                font-weight: 700;
                margin-bottom: @tiny-spacing;
                white-space: nowrap;
            }
        }
    }
}

.react-select__menu {
    .react-select__group {
        padding-top: 0;
        padding-bottom: 0;
    
        .react-select__group-heading {
            font-weight: 700;
            font-size: @small-font-size;
            text-transform: uppercase;
            color: @secondary-font-color;
            padding: 8px 12px; // match other options' padding
        }
    }

    .react-select__menu-notice--no-options {
        color: @main-font-color;
    }
}

.accordion {
    width: 60%;

    margin-bottom: @default-spacing;
}

.new-form {
    .submission {
        display: block;
    }
}