@media print {
  body {
    font-size: 12pt !important;
  }

  .breadcrumb-bar {
    background: none !important;
    display: inline-block !important;
    margin-left: 30px !important;
    margin-top: 10px !important;

    a, .breadcrumbs {
      color: @secondary-font-color !important;
    }
  }

  .wrapper {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    clear: both !important;
  }

  .remote-control, .remote-control-wrapper {
    display: none;
  }

  .MuiSvgIcon-root {
    display: none !important;
  }
}

@page {
  margin: 0.8cm;
}