.edit-schedule {
  .podiums {
    border-bottom: 2px solid @border-light;
    border-top: 1px solid @border-light;

    .time {
      color: transparent;
    }

    .time, .podium {
      padding: @tiny-spacing @default-spacing;
    }

    .podium {
      background-color: white;
    }
  }

  .podium, .heat, .empty {
    display: inline-flex;
    align-items: flex-start;
    width: calc(50% - 41px);
    border-right: 1px solid @border-light;
  }

  .time {
    position: sticky;
    position: -webkit-sticky;
    display: inline-block;
    width: 82px;
    left: 0;
    border-right: 2px solid @border-light;
    z-index: 1;
    background-color: white;
    padding: @medium-spacing @default-spacing;
    @media print {
      padding: @small-spacing @default-spacing;
    }
  }

  .break, .empty {
    padding: @medium-spacing (@default-spacing+@tiny-spacing);
    @media print {
      padding: @small-spacing (@default-spacing+@tiny-spacing);
    }
  }

  .drop-row.isHidden {
    display: none;
  }

  .heat, .drop-row, .empty {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;

    &.isOver {
      * {opacity: 0;}
      background: @dragging-color none;
    }
  }

  .heat, .break, .empty {
    border-bottom: 1px solid @border-light;
  }

  .drag-handle {
    position: absolute;
    left: @tiny-spacing;
    top: 14px;
    cursor: grab;

    &.hide {
        display: none;
    }
  }

  &.drag-in-progress {
    .drop-row:not(.canDrop) .heat.cannot-drop, .drop-row:not(.canDrop) .empty.cannot-drop, .drop-row.cannot-drop .time, .drop-row.cannot-drop .break {
      * {opacity: 0.6;}
      background: repeating-linear-gradient(
              45deg,
              rgba(255, 255, 255, 0.5),
              rgba(255, 255, 255, 0.5) 5px,
              rgba(150, 150, 150, 0.5) 5px,
              rgba(150, 150, 150, 0.5) 6px
      );
    }

    .drag-handle {
      cursor: grabbing;
    }
  }

  &:not(.drag-in-progress) {
    .heat.can-drag {
      &.selected {
        background-color: @drag-selected-color;
      }

      &:not(.selected):hover {
        background-color: @drag-hover-color;
        opacity: 0.9;
      }
    }
  }

  .heat, .break {
    position: relative;
    background-color: white;

    .edit-icon {
      position: absolute;
      right: @tiny-spacing;
    }
  }

  .heat {
    align-items: center;
    padding: @small-spacing (@default-spacing+@small-spacing) @small-spacing (@default-spacing+@tiny-spacing);
    @media print {
      padding: @tiny-spacing (@default-spacing+@small-spacing) @tiny-spacing (@default-spacing+@tiny-spacing);
    }

    &.finished {
      .division, .round-heat {
        text-decoration: line-through;
      }
    }

    &.live {
      font-weight: bold;

      .live-tag {
        margin: 0 @tiny-spacing 0 auto;
      }
    }

    .text, .round-heat {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    .division, .round-name {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .heat-number {
      white-space: nowrap;
    }
  }

  @media @generic-phone {
    width: 160vw;

    .podiums .time {
      height: auto;
    }

    .heat, .empty, .break {
      height: 4em;
      vertical-align: top;
      user-select: none;
    }

    .time {
      height: 4em;
      user-select: none;
    }

    .break {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      width: 100vw;
    }

    .heat {
      .text {
        flex-wrap: wrap;
      }
    }
  }
}
