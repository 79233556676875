@keyframes pulse-strong {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

header.heat-header {
    padding: @default-spacing;
    color: @text-contrast;
    font-size: 18px;
    background: @dark-background-color;

    .heat-actions {
        float: right;
    }

    .heat-time {
        margin-left: @small-spacing;
        padding-left: @small-spacing;
        border-left: 1px solid @card-surface-color;

        @media @generic-phone-portrait {
            margin: 0;
            padding: 5px 0 0 0;
            border: 0;
            display: block;
        }

        svg.done {
            width: 12px;
            height: 12px;
            color: @info-color;
            vertical-align: text-top;

            // make icon bolder
            stroke: @info-color;
            stroke-width: 2;
        }

        &.flag span {
            background-color: @status-upcoming;
            border-radius: 4px;
            font-weight: 700;
            padding: 2px @tiny-spacing;
            color: @status-upcoming-text;
            white-space: nowrap;
        }

        div {
          display: inherit;
        }
    }
}

.timer-wrapper.warning {
    animation: pulse-strong 2s infinite;
}