@import (inline) "~react-datepicker/dist/react-datepicker.css";

@import "palette";
@import "devices";
@import "buttons";
@import "modal";
@import "forms/index";
@import "login/index";
@import "events/dashboard/dashboard";
@import "events/edit";
@import "events/spectate";
@import "registration/registerOrLoginModal";
@import "events/schedules/editSchedule";
@import "events/heats/card";
@import "events/heats/tinyCard";
@import "events/judging/judgeCard";
@import "events/heats/editHeat";
@import "events/judging/judgeSheet";
@import "events/heats/header";
@import "events/manage/remoteControl";
@import "events/broadcast";
@import "organisations/list";
@import "DnD/dragLayers";
@import "print";
@import "../styleguide/patterns/patterns";
@import "../styleguide/icons/icons";
@import "legal/privacyPolicy/PrivacyPolicy";
@import "eventDivisions/eventDivisionSettingsDialog";
@import "organisations/series/series-form";

@page-max-width: 1200px;
@default-spacing: 16px;
@tiny-spacing: 4px;
@small-spacing: 8px;
@medium-spacing: 12px;
@main-font-size: 16px;
@bigger-font-size: 18px;
@small-font-size: 13px;
@callout-font-size: 24px;
@tiny-font-size: 10px;

@roboto: "Roboto", sans-serif;
@inter: "Inter", sans-serif;

h1, h2, h3, h4 {
    font-family: @inter;
    font-weight: 700;

    .small {
        font-size: 16px;
        margin-left: @small-spacing;
        cursor: pointer;
        font-weight: normal;
    }

    .small:not(.button) {
        text-decoration: underline;
    }
}

a {
    color: inherit;
    text-decoration: none;

    &:visited {
        color: inherit;
    }
}

p a {
    text-decoration: underline;
}

html {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
}

.wrapper {
    padding: @default-spacing;
    margin: 0 auto;
    width: calc(100vw - (100vw - 100%));
    max-width: @page-max-width;
}

.clear {
    clear: both;
}

.card {
    background-color: white;
    margin-bottom: 1em;
}

.ReactModal__Body--open {
    overflow: hidden;
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0.3;
    }
}

.skeleton {
    animation: pulse 500ms infinite;

    .fake {
        background-color: @disabled-input-text-color;
        border-radius: 2px;
        word-break: break-word;
    }

    .skeleton {
        animation: none;
    }
}