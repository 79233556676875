.broadcast {
  @row-height: 45px;
  @width: 440px;
  width: 1920px;
  min-height: 1080px;
  padding: 80px;
  background-color: #00b140;

  &.transparentBackground {
    background-color: transparent;
  }

  &.broadcast-message {
    color: white;

    .offline-message {
      width: @width;
      height: 280px;
      background: @brand-primary;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text {
        margin-top: @default-spacing;
        font-size: @bigger-font-size;
        text-align: center;
      }
    }
  }

  .top-scores-header {
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    background-color: @dark-background-color;
    color: white;
    opacity: 0;
    position: absolute;
    top: 80px;
    left: 523px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
  }

  .live-heats {
    width: @width;
  }

  .heat-card {
    border: 0;
    margin: 0;
  }
  
  header.heat-header {
    font-size: 20px;
    min-height: 46px;
    padding: @small-spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .heat-time {
      display: flex;
      border: 0;
      padding-top: 0;
    }

    .timer-wrapper {
      margin-left: @tiny-spacing;
    }
  }

  .athlete-table {
    border: 0;
    border-collapse: separate;
    border-spacing: 0 3px;
    width: 440px;

    .athlete-heat-row {
      height: @row-height;
      background-color: initial;

      td, th {
        background-color: fade(white, 85);
        border: 0;
      }

      .place {
        min-width: 32px !important;
        width: 32px;
        font-size: @bigger-font-size;
        padding: 0;
      }

      .avatar {
        display: none;
      }

      .name {
        font-size: 24px;
        padding: @small-spacing;
        font-weight: 700 !important;
        min-width: 314px;
        width: 314px !important;
      }

      @priority-width: 40px;

      &.with-priority .name {
        min-width: 314px - @priority-width;
        width: 314px - @priority-width !important;
      }

      .team-name {
        font-size: @bigger-font-size !important;
        margin-top: 0 !important;
        font-weight: 400 !important;
      }

      .overall {
        border: 0;
        background: white;
        min-width: 94px;
        width: 94px;
        padding: 0 @small-spacing * 1.5;

        .total {
          margin-bottom: 0;
          min-width: 0;
          text-align: right;
          line-height: 1;

          &.no-score {
            text-align: inherit;
          }
        }

        .win-by-needs {
          display: none;
        }
      }

      .all-ride-scores {
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        opacity: 0;
        background-color: transparent;

        .rides-container  {
          flex-wrap: nowrap;
        }

        .ride {
          width: 70px;
          margin-left: 3px;
          max-height: 0;

          sup {
            display: none;
          }

          &.top {
            color: white;
            font-weight: 500;
            background-color: @dark-background-color;
          }

          &:not(.top) {
            display: none;
          }
        }
      }
    }
  }

  &.showScores {
    .top-scores-header, .athlete-table .athlete-heat-row .all-ride-scores {
      opacity: 1;
    }

    .athlete-table .athlete-heat-row .all-ride-scores .ride {
      max-height: initial;
    }
  }
}