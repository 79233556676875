.judge-dialog {
  p {
    margin-bottom: 0;
  }

  .MuiDialogContent-root {
    padding-bottom: 24px;
    padding-top: 0;
  }

  button {
    width: 100%;
    &:not(:first-child) {
      margin-top: @small-spacing;
    }

    &.selected {
      background-color: @side-nav-bg-color;
      color: white;
    }
  }
}

.judge-sheet-overlay {
  z-index: 3 !important;
}

.judge-sheet {

  .heat-header {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    width: 100vw;
    background: @judging_heat_header_color;
    color: white;
    padding: @small-spacing 0 @default-spacing @default-spacing;
    z-index: 4;
    cursor: pointer;

    .MuiSvgIcon-root {
      margin-top: -@small-spacing;
      padding-top: @tiny-spacing;
      margin-left: @small-spacing;
    }
  }

  .toolbar {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    background: @judge-sheet-toolbar-background;
    display: flex;
    width: 100vw;

    .timer-wrapper {
      font-size: 30px;
      font-weight: bold;
      margin-left: @default-spacing;
      color: white;
      padding: @small-spacing 0;
    }

    button {
      margin-left: auto;
      border-radius: 0;

      &.selecting {
        background: @focus-color;
        color: @main-font-color;
      }
    }
  }

  table.judge-card tbody tr {
    height: auto;
  }

  @cell-size: 70px;

  .judge-card tbody .ride {
    text-align: center;
    max-width: @cell-size;

    .missing-score {
      position: absolute;
      top: 0; left: 0; right: 0;
      font-size: @tiny-font-size;
      color: @warning-text-color;
      background: @warning-background-color;
      text-align: center;
      padding: @tiny-spacing 0;
    }

    &.modified:not(.Double) {
      text-decoration: line-through;
    }
  }

  .judge-card {
    tbody {
      td:not(.ride) button {
        margin-left: @small-spacing*1.5;
      }
    }
  }

  .judge-card tbody td:not(.priority-cell) {
    cursor: pointer;
    min-width: @cell-size;
    height: 80px;
    vertical-align: middle;
    padding: 0;

    &.edit-locked > * {
      opacity: 0.5;
    }

    form, form .formField, .input-wrapper {
      height: 100%;
    }

    @keyframes pulse {
      0%   {border-color: lighten(@focus-color, 15%);}
      50%  {border-color: @focus-color;}
      100% {border-color: lighten(@focus-color, 15%);}
    }

    .judge-total {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      &.error {
        border: 2px solid @error-color;
      }

      &.submitting {
        animation: pulse 500ms infinite;
      }

      .ride-category {
        position: absolute;
        top: @tiny-spacing;
        left: @tiny-spacing;
      }
    }

    &, input {
      font-size: @callout-font-size;
    }

    &.team-filler {
      min-width: 0;
      width: 100%;
    }
  }
}